import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { oo } from "../element/theme";


const rows = [
  {
    heading: "What is Oberion?",
    text: "Lorem Ipsum",
    expanded: true,
  },
  {
    heading: "Why GitHub App?",
    text: "Lorem Ipsum",
    expanded: true,
  },
  {
    heading: "What can I do with the website code?",
    text: "Lorem Ipsum",
    expanded: true,
  },
  {
    heading: "How do I update an existing site?",
    text: "Start new from \"Create website\".",
    expanded: true,
  },
  {
    heading: "Can I customize the styles of my website without breaking it?",
    text: "Yes! ",
    expanded: false,
  },
];


const FaqPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {rows.map((row, index) => {
        return (
          <Accordion defaultExpanded={row.expanded} key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header">
              <Typography component="h2" variant="h6">{row.heading}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{row.text}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
export default FaqPage;


const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: oo.contentWidth + "rem",
  },
});