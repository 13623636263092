import React from "react";
import Layout from "../components/element/layout/Layout";
import PageBanner from "../components/element/page-element/PageBanner";
import LayoutMain from "../components/element/layout/LayoutMain";
import FaqPage from "../components/faq/FaqPage";
import NewsletterBanner from "../components/navigation/footer/NewsletterBanner";


const Faq = () => {
  return (
    <Layout showNewsletter>
      <PageBanner heading="Frequently Asked Questions" />
      <NewsletterBanner />
      <LayoutMain>

        <FaqPage />

      </LayoutMain>
    </Layout>
  );
};
export default Faq;